import { createStore } from "vuex";
export default createStore({
  state: {
    isLoading: false,
    logedIn: false,
    token: localStorage.getItem("token"),
    _id: "",
    username: "",
    partner_name: "",
    partner_phone: "",
    partner_email: "",
    partner_iden: "",
    position: "",
    status_otp: "",
    status_appover: "",
    showProfile: false
  },
  getters: {
    isLoading: (state) => state.isLoading,
    logedIn: (state) => state.logedIn,
    token: (state) => state.token,
    _id: (state) => state._id,
    username: (state) => state.username,
    partner_name: (state) => state.partner_name,
    partner_phone: (state) => state.partner_phone,
    partner_email: (state) => state.partner_email,
    partner_iden: (state) => state.partner_iden,
    position: (state) => state.position,
    status_otp: (state) => state.status_otp,
    status_appover: (state) => state.status_appover,
    showProfile: (state) => state.showProfile
  },
  mutations: {
    setLoading(state, item) {
      state.isLoading = item;
    },
    setLogin(state, item) {
      state.logedIn = item.logedIn;
      state._id = item._id;
      state.username = item.username;
      state.partner_name = item.partner_name;
      state.partner_phone = item.partner_phone;
      state.partner_email = item.partner_email;
      state.partner_iden = item.partner_iden;
      state.position = item.position;
      state.status_otp = item.status_otp;
      state.status_appover = item.status_appover;
    },
    setLoginDefault(state) {
      state.logedIn = false;
      state._id = "";
      state.username = "";
      state.partner_name = "";
      state.partner_phone = "";
      state.partner_email = "";
      state.position = "";
      state.status_otp = "";
      state.status_appover = "";
      state.token = "";

      localStorage.removeItem('token');
    },
    setToken(state, item) {
      state.token = item;
    },
    setShowProfile(state, item) {
      state.showProfile = item;
    },
    openLoading(state) {
      state.isLoading = true;
    },
    closeLoading(state) {
      state.isLoading = false;
    },

    ClearLogin(state) {
      state.logedIn = false;
      state._id = "",
      state.username ="",
      state.partner_name = "",
      state.partner_phone = "",
      state.partner_email = "",
      state.position = "";
      state.status_otp = "";
      state.status_appover = "";
    },

  },
  actions: {},
  modules: {},
});
