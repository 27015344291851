<template>
  <section class="bg-[#17594A] ">
    <nav class="hidden md:flex items-center justify-between flex-wrap border-t-2
  border-[#B6EB7A] p-6 lg:flex lg:items-center lg:justify-between">

      <div class="flex justify-between lg:w-auto w-full lg:border-b-0 pl-6 pr-2 border-solid  
   pb-5 lg:pb-0">
        <div class="flex items-center flex-shrink-0 text-gray-800 mr-16">
          <img src="../../assets/000.png" :width="80" alt="" @click="linkindex()" />
        </div>

        <!-- Mobile menu button -->
        <div class="block lg:hidden">
          <button @click="isOpen = !isOpen" type="button" class="flex items-center px-3 py-2 border-2 rounded border-[#D23369] 
           hover:border-[#F7FD04] text-[#F7FD04]">
            <svg v-show="!isOpen" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4 8h16M4 16h16" />
            </svg>

            <svg v-show="isOpen" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>

          </button>
        </div>



      </div>

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <div :class="[isOpen ? 'translate-x-0 opacity-100 ' : 'opacity-0 -translate-x-full']"
        @click="closeSubMenuOnClickOutside" class="absolute inset-x-0 z-20 w-full  bg-white px-6 py-4 shadow-md transition-all 
       duration-300 ease-in-out  lg:relative lg:top-0
        lg:mt-0 lg:flex lg:w-auto lg:translate-x-0 lg:items-center lg:bg-transparent lg:p-0 
        lg:opacity-100 lg:shadow-none lg:dark:bg-transparent">

        <div class="lg:-px-8 flex  flex-col space-y-4 lg:mt-0 lg:flex-row lg:space-y-0">
          <div v-for="menuItem in menuItems" :key="menuItem.id" class="relative lg:inline-block text-center"
            ref="submenuButton">
            <button @click="toggleSubMenu(menuItem)"
              :class="{ 'text-[#004225] hover:text-[#1C7947]': isMobile, 'text-white hover:bg-[#F7FD04]  hover:text-[#001B79]': !isMobile }"
              class="transform transition-colors duration-300 px-4 py-2 rounded lg:mx-4">{{ menuItem.label }}
            </button>

            <div v-if="isSubMenuOpen && activeMenuItem === menuItem && menuItem.subItems" ref="submenuContent"
              class="-translate-x-1/2 absolute left-1/2  mt-2 w-48 bg-white border border-gray-300 py-2 rounded-lg shadow-lg z-10">
              <button v-for="subItem in menuItem.subItems" :key="subItem.id"
                @click="navigateAndCloseMenu(subItem.route)"
                class="block text-start w-full px-4 py-2 hover:text-white hover:bg-[#41644A]">
                {{ subItem.label }}
              </button>
            </div>
          </div>

        </div>

      </div>

      <button @click="confirmLogout"
        :class="{ 'bg-[#FAF35E] text-[#15133C]': isMobile, 'bg-[#FAF35E] text-[#001B79]': !isMobile }"
        class="mt-4 block rounded-lg  px-6 py-2.5 text-center font-medium capitalize leading-5  hover:bg-[#F7FD04] lg:mt-0 lg:w-auto">
        ออกจากระบบ</button>

    </nav>

    <nav class="fixed bottom-0 md:hidden bg-[#17594A] w-full h-[70px] z-[1000]">
      <div class="h-full flex justify-around items-center w-full relative">
        <div ref="icon"
          class="cursor-pointer w-full flex justify-center focus:ring-0 duration-300 ease-in-out transition-transform"
          :class="menu === 1 ? '-translate-y-2' : ''" @click="() => {
            $router.push('/')
            menu = 1
          }">
          <i class="pi pi-home text-4xl text-center duration-300 hover:text-green-400"
            :class="menu === 1 ? 'text-green-400' : 'text-white'">
          </i>
        </div>
        <div
          class="cursor-pointer flex  w-full justify-center focus:ring-0 duration-300 ease-in-out transition-transform"
          :class="menu === 2 ? '-translate-y-2' : ''" @click="menu = 2">
          <i class="pi pi-file text-4xl text-center duration-300 hover:text-green-400"
            :class="menu === 2 ? 'text-green-400' : 'text-white'">
          </i>
        </div>
        <div
          class="cursor-pointer w-full flex justify-center focus:ring-0 duration-300 ease-in-out transition-transform"
          :class="menu === 3 ? '-translate-y-2' : ''" @click="menu = 3">
          <i class="pi pi-qrcode text-4xl text-center duration-300 hover:text-green-400"
            :class="menu === 3 ? 'text-green-400' : 'text-white'">
          </i>
        </div>
        <div
          class="cursor-pointer w-full flex justify-center focus:ring-0 duration-300 ease-in-out transition-transform"
          :class="menu === 4 ? '-translate-y-2' : ''" @click="() => {
            menu = 4
            $store.commit('setShowProfile', true)
          }">
          <i class="pi pi-user text-4xl text-center duration-300 hover:text-green-400"
            :class="menu === 4 ? 'text-green-400' : 'text-white'">
          </i>
        </div>
        <div
          class="cursor-pointer w-full flex justify-center focus:ring-0 duration-300 ease-in-out transition-transform"
          :class="menu === 5 ? '-translate-y-2' : ''" @click="() => {
            confirmLogout()
            menu = 5
          }">
          <i class="pi pi-power-off text-center text-2xl duration-300 hover:text-green-400"
            :class="menu === 5 ? 'text-green-400' : 'text-white'">
          </i>
        </div>

        <div
          class="bg-black/50 absolute top-0 left-0 h-14 w-1/5 duration-300 transition-transform ease-in-out -z-10 rounded-b-[30px]"
          :class="menu === 1 ? ''
            : menu === 2 ? 'translate-x-[100%]'
              : menu === 3 ? 'translate-x-[200%]'
                : menu === 4 ? 'translate-x-[300%]'
                  : 'translate-x-[400%]'
            ">
        </div>


      </div>
    </nav>

  </section>
</template>

<script>
import Swal from 'sweetalert2';
import { Partner } from '@/service/partner';

export default {
  components: {

  },
  setup() {
    const partner = new Partner();
    return { partner }
  },
  data() {
    return {
      isOpen: false,
      isMobile: false,
      isSubMenuOpen: false,
      activeMenuItem: null,
      menuItems: [],
      status_appover: "",
      menu: 1,
    };
  },

  async mounted() {
    await this.loadUserData();
    this.isMobile = window.innerWidth < 913;
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('click', this.closeDropdownOnClickOutside);

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('click', this.closeDropdownOnClickOutside);

  },
  methods: {

    async linkindex() {
      this.$router.push('/');
    },
    async loadUserData() {
      await this.partner.Getbypartnerid(this.$store.getters._id).then(async (res) => {
        this.status_appover = res?.data?.status_appover;
      })


      if (this.status_appover == "อนุมัติแล้ว") {
        this.menuItems = [
          { id: 1, label: 'หน้าแรก', route: '/' },

          {
            id: 4, label: 'ระบบฝากขายสินค้า /E-marketplace', subItems: [
              { id: 1, label: 'คำร้องขอฝากขายสินค้า', route: '/partner/requstproduct' },
              { id: 2, label: 'สินค้าของคุณ', route: '/partner/productview' },
              { id: 3, label: 'ออเดอร์ของคุณ', route: '/partner/orderpartnerview' }
            ]
          },
          {
            id: 2, label: 'ระบบฝากขายสินค้า /Tossagun Shop',
            subItems: [
              { id: 1, label: 'ฝากขายสินค้า', route: '/addproducttg' },
              { id: 2, label: 'สินค้า', route: '/producttg' },
              { id: 3, label: 'รายการออเดอร์', route: '/producttg/order' },
            ]
          },
          {
            id: 5, label: 'ระบบ พาร์ทเนอร์ / POS', subItems: [
              { id: 1, label: 'คำร้องขอเปิดร้านค้า', route: '/partner/requestshop' },
              { id: 2, label: 'ร้านค้าของคุณ', route: '/partner/shopview' },
            ]
          },

          { id: 6, label: 'รายงานการลงทุน', route: '/partner/register/list' },

          {
            id: 7, label: 'ติดตามงาน', subItems: [
              { id: 1, label: 'ติดตามงาน', route: '/partner/followupwork' },
            ]
          }
        ]
      } else {
        this.menuItems = [
          { id: 1, label: 'หน้าแรก', route: '/' },]
      }

    },

    toggleSubMenu(menuItem) {
      if (menuItem.route) {
        this.navigateAndCloseMenu(menuItem.route);
      } else {
        this.isSubMenuOpen = !this.isSubMenuOpen;
        this.activeMenuItem = menuItem;
      }
    },

    navigateAndCloseMenu(route) {
      this.$router.push(route);
      this.isSubMenuOpen = false;
    },
    handleResize() {
      this.isMobile = window.innerWidth < 821;
    },

    closeDropdownOnClickOutside(event) {
      const dropdownButtons = this.$refs.submenuButton;
      const dropdownContents = this.$refs.submenuContent;

      // Check if dropdownButtons is an array and not empty
      const isButtonsArray = Array.isArray(dropdownButtons) && dropdownButtons.length > 0;

      // Check if dropdownContents is an array and not empty
      const isContentsArray = Array.isArray(dropdownContents) && dropdownContents.length > 0;

      // Check if the event target is outside all dropdown buttons and contents
      const isOutsideDropdown = isButtonsArray && isContentsArray &&
        !dropdownButtons.some(button => button.contains(event.target)) &&
        !dropdownContents.some(content => content.contains(event.target));

      if (isOutsideDropdown) {
        this.isSubMenuOpen = false;
      }
    },


    async confirmLogout() {
      const confirmResult = await Swal.fire({
        text: 'คุณต้องการออกจากระบบหรือไม่?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'ใช่, ออกจากระบบ',
        confirmButtonColor: '#E0144C',
        cancelButtonText: 'ยกเลิก',
      });

      if (confirmResult.isConfirmed) {
        this.logout();
      }
    },

    logout() {
      localStorage.clear();
      this.$store.commit("setLoginDefault");
    },

  },

};
</script>

<style></style>


<style scoped>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
</style>